<script setup>
import CIcon from '@/components/common/CIcon.vue';

const props = defineProps({
  icon: {
    type: String,
    default: 'close',
  },
});

const emit = defineEmits(['click']);

const emitClick = (e) => {
  emit('click', e);
};
</script>
<template>
  <button type="button" class="ui-button-close" @click="emitClick">
    <CIcon :name="props.icon" class="ui-button-close__icon" />
  </button>
</template>

<style scoped lang="scss">
.ui-button-close {
  $parent: &;

  display: flex;
  align-items: center;
  justify-content: center;
  width: em(36);
  height: em(36);
  background-color: $color-white;
  border: none;
  border-radius: em(12);
  transition: all $time-default ease-out;

  @include hover {
    color: $color-red-1;
  }

  &__icon {
    flex-shrink: 0;
    width: em(24);
    height: em(24);
  }
}
</style>
